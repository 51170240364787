@tailwind base;
@tailwind components;
@tailwind utilities;

/* Sidebar Styling */
.sidebar {
    display: flex;
    flex-direction: column;
    max-height: 100vh; /* Ensure sidebar fits within the viewport */
    overflow: hidden; /* Prevent overflow */
  }
  
  /* Ensure DatePicker Fits Within Sidebar */
  .custom-calendar-container {
    flex-shrink: 0; /* Prevent the container from shrinking */
    overflow: hidden; /* Prevent overflow of the calendar */
  }
  
  .custom-calendar {
    max-height: 300px; /* Limit DatePicker height */
    overflow-y: auto; /* Allow scrolling inside the DatePicker if needed */
    width: 240px; /* Ensure DatePicker fits horizontally */
  }
  
  .highlighted-event-date {
    background-color: #029E9B !important; /* Highlight specific dates */
    color: #fff !important;
  }
  